import './style.css';

let nftMobileMenuActive = false;
/**
 * @description Toggle mobile menu
 */
function toggleMobileMenu() {
  // get mobile menu
  let menu = document.querySelector('.nav-bar');
  // toggle the openclose menu button
  document.querySelector('.nav-bar__toggle-btn').classList.toggle('open');
  // reveal mobile menu
  menu.classList.toggle('open');
}

/**
 * @description FAQs accordion
 */
function faqsAccordion() {
  // use closure to keep track of the last element clicked
  let lastClick = '';
  document.querySelectorAll('.faqs-question').forEach(q => {
    q.addEventListener('click', e => {
      // close answer previously opened
      if (lastClick !== '') {
        lastClick.classList.toggle('open');
      }
      // remove last clicked question
      if (lastClick == e.target) {
        lastClick = '';
      } else {
        // update lastClick with last clicked question and open its answer
        lastClick = e.target;
        e.target.classList.toggle('open');
      }
    });
  });
}

/**
 * @description Preps the mobile menu based on viewport width
 */
function mobileMenuInit() {
  // setup mobile menu
  if (!nftMobileMenuActive && window.innerWidth < 600) {
    // listen to the menu GamepadButton, open and close
    document.querySelector('.nav-bar__toggle-btn').addEventListener('click', toggleMobileMenu);
    // when user tabs on any part of the opened mobile menu, it closes
    document.querySelector('.nav-bar').addEventListener('click', toggleMobileMenu);
    nftMobileMenuActive = true;
  }else if (nftMobileMenuActive && window.innerWidth >= 600) {
    // removes all listeners from mobile menu
    document.querySelector('.nav-bar__toggle-btn').removeEventListener('click', toggleMobileMenu);
    document.querySelector('.nav-bar').removeEventListener('click', toggleMobileMenu);
    nftMobileMenuActive = false;
  }
}

window.onload = function() {
  // mobile menu
  mobileMenuInit();
  // faqs accordion
  faqsAccordion();
}

window.onresize = function() {
  // check the mobile menu listeners
  mobileMenuInit();
}
